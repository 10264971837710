






























































import Base from '@/mixins/Base.vue';

import { ISurvey, ISurveyResponse } from '@/interfaces/survey';

const component = Base.extend({
    data() {
        return {

            active: false,
            survey: {} as ISurvey,
        };
    },
    mounted() {
        this.active = true;
    },
    methods: {
        save(): void {
            this.post<ISurveyResponse>('surveys', this.survey).then(({ data }) => {
                this.$router.push({ name: 'surveys.edit', params: { id: data.data.id.toString() } });
            });
        },
    },
});

export default component;
